import React from 'react';
import { GetTemplateComponentsType } from '@display-component/pc/dist/types/display-service';
import { ApiResponse } from '@display-component/shared';
import { NewDisplayService } from '@display-component/pc';
import { useAuthenticated, useDisplayComponent } from '@/hooks';
import { DISPLAY_SERVICE_MAIN_PC_INT } from '@/constants/display-service';
import { FloatingGroup } from '@/components/FloatingGroup';

export interface InteriorMainProps {
  data: ApiResponse<GetTemplateComponentsType[]>;
}

const InteriorMain = ({ data }: InteriorMainProps) => {
  const { handleNewLink, handleAction } = useDisplayComponent();
  const { isAuthenticated } = useAuthenticated();

  return (
    <>
      <NewDisplayService
        pageIdentificationValue={DISPLAY_SERVICE_MAIN_PC_INT}
        onHandleClick={handleNewLink}
        onHandleAction={handleAction}
        isLogin={isAuthenticated}
        data={data}
        isDataRetry={true}
        style={{
          paddingBottom: '160px',
        }}
      />
      <FloatingGroup />
    </>
  );
};

export default InteriorMain;
