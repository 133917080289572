import React from 'react';
import { NextSeo } from 'next-seo';
import AppLayout from '@/components/AppLayout';
import { displayServiceServerGateway } from '@display-component/pc';
import { InteriorMain } from '@/feat-components/InteriorMain';
import { axios } from '@/di-container/axios';
import { wrapper } from '@/redux/store';
import { DISPLAY_SERVICE_MAIN_PC_INT } from '@/constants/display-service';

const InteriorMainPage = (props: any) => {
  return (
    <>
      <NextSeo
        noindex={process.env.NEXT_PUBLIC_STAGE !== 'production'}
        nofollow={process.env.NEXT_PUBLIC_STAGE !== 'production'}
      />
      {props?.data && (
        <AppLayout
          layoutType="responsive"
          isHeaderBackgroundColor={false}
          content={<InteriorMain {...props.data} />}
        />
      )}
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  () => async (ctx) => {
    const gateway = await displayServiceServerGateway(
      process.env.NEXT_PUBLIC_BASE_API_HOST || '',
      axios,
    ).getTemplateComponents.exec(
      {
        pageIdentificationValue: DISPLAY_SERVICE_MAIN_PC_INT,
      },
      ctx.req.headers.cookie || '',
    );

    return {
      props: { id: DISPLAY_SERVICE_MAIN_PC_INT, data: gateway },
    } as any;
  },
);

export default InteriorMainPage;
